<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/raceManagement/team_management/team_race_management' }">团体赛赛务管理 </el-breadcrumb-item>
      <el-breadcrumb-item>报名信息管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
        <registerForm></registerForm>
  </div>
</template>

<script>
import registerForm from '../components/itso_register_form.vue'

export default {
  components: { registerForm },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
</style>
